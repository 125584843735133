// dependencies
import React from "react";
import { View, ScrollView } from "react-native";
import { useIntl } from "react-intl";
import Head from "next/head";

// components
import ContentMosaic from "@gdf/resources/src/components/ContentMosaic";
import ContentPage from "@gdf/resources/src/components/ContentPage";
import { Row, Col } from "@gdf/resources/src/components/Grid";
import { List, ListItem } from "@gdf/resources/src/components/List";
import HomepageSlider from "@gdf/resources/src/components/HomepageSlider";
import ContentSection from "@gdf/resources/src/components/ContentSection";
import HasSeenRoom from "@gdf/front/components/HasSeenRoom";

// containers
import ContentCampaignContainer from "@gdf/resources/src/containers/ContentCampaign";
import LastSeenRoomContainer from "../LastSeenRoom";
import SearchContainer from "./Search";
import RejoindreHomepage from "../Rejoindre/Homepage";

// contexts
import { useConfiguration } from "@gdf/resources/src/contexts/configuration";
import { useRouters } from "@gdf/resources/src/contexts/routers";

// constants
import {
  CONTENT_SECTION_TYPES,
  CONTENT_SECTION_PAGE_VALUE_HOME,
  CONTENT_SECTION_TYPE_VALUE_MOSAIC,
  CONTENT_SECTION_TYPE_VALUE_PAGE,
  CONTENT_SECTION_TYPE_VALUE_CAMPAIGN,
  CONTENT_ALIAS_VALUE_LABEL,
} from "@gdf/resources/src/constants/content";
import { SEO_PAGE_VALUE_HOME } from "@gdf/resources/src/constants/seo";

// helpers
import { contentGenerateUriRelayOnType } from "@gdf/resources/src/helpers/content";
import { seoGetContentRelayOnConfiguration } from "@gdf/resources/src/helpers/seo";

// hocs
import useViewportMedia from "@gdf/resources/src/hocs/useViewportMedia";

// hooks
import { useResponse } from "@gdf/resources/src/hooks/fetcher";

// libraries
import { emToPx } from "@gdf/resources/src/libraries";
import { get } from "@gdf/shared/src/libraries";

// api
import { slideHome } from "@gdf/resources/src/api/slide";

function IndexContainer() {
  const intl = useIntl();

  const configuration = useConfiguration();

  const { data: slideSearch } = useResponse(slideHome());

  let colorIndex = 0;

  const { matches } = useViewportMedia({ queries: [{ minWidth: 800 }] });

  const { frontRouter } = useRouters();

  return (
    <>
      <Head>
        {seoGetContentRelayOnConfiguration({
          configuration,
          page: SEO_PAGE_VALUE_HOME,
        })}
      </Head>

      <View accessibilityRole="section">
        <HomepageSlider
          slideList={slideSearch?.data ?? []}
          form={<SearchContainer />}
        />
      </View>

      {get(
        configuration,
        [
          "channel",
          "website",
          "contents",
          "sections",
          CONTENT_SECTION_PAGE_VALUE_HOME,
        ],
        []
      ).map((contentSection) => {
        const contentList =
          configuration.channel.website.contents.bySection[
            contentSection.alias
          ] || [];

        if (contentList.length === 0) {
          // Il n'y a pas de content à afficher.

          return null;
        }

        const backgroundColor = colorIndex++ % 2 === 0 ? "#f7f7f7" : "#ffffff";

        const getContentNode = ({ content }) => {
          switch (contentSection.type) {
            case CONTENT_SECTION_TYPE_VALUE_MOSAIC: {
              return (
                <ContentMosaic
                  title={content.otherTitle}
                  subtitle={content.otherSubtitle}
                  imageSource={{
                    uri: get(content.coverUrls, "medium"),
                  }}
                  to={contentGenerateUriRelayOnType({
                    content,
                    frontRouter,
                    intl,
                  })}
                />
              );
            }

            case CONTENT_SECTION_TYPE_VALUE_PAGE: {
              if (CONTENT_ALIAS_VALUE_LABEL === content?.alias) {
                return <RejoindreHomepage content={content} />;
              }

              return (
                <ContentPage
                  title={content.title}
                  subtitle={null}
                  about={content.about}
                  fadeColor={backgroundColor}
                  to={contentGenerateUriRelayOnType({
                    content,
                    frontRouter,
                    intl,
                  })}
                  imageSource={{
                    uri: get(content.coverUrls, "large"),
                  }}
                />
              );
            }

            case CONTENT_SECTION_TYPE_VALUE_CAMPAIGN: {
              return (
                <ContentCampaignContainer
                  context="home"
                  contentId={content.id}
                  perPage={"5"}
                />
              );
            }
          }
        };

        return (
          <ContentSection
            key={contentSection.id}
            title={contentSection.title}
            subtitle={contentSection.subtitle}
            useTitle={CONTENT_SECTION_TYPES[contentSection.type].useTitle}
            addon={null}
            color={backgroundColor}
          >
            {CONTENT_SECTION_TYPE_VALUE_MOSAIC === contentSection.type && (
              <>
                {matches ? (
                  <Row
                    breakpointList={{
                      xs: 500,
                      sm: 800,
                      md: 1000,
                      lg: Infinity,
                    }}
                    col={{ xs: 1, sm: 2, md: 3, lg: 5 }}
                    gutter={0.625}
                  >
                    {contentList.map((content) => {
                      return (
                        <Col key={content.id}>
                          {getContentNode({ content })}
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <ScrollView
                    scrollEnabled={true}
                    horizontal={true}
                    style={{ paddingBottom: emToPx(1) }}
                  >
                    <List inline={true} gutter={0.625} noWrap={true}>
                      {contentList.map((content) => {
                        return (
                          <ListItem
                            key={content.id}
                            style={{
                              flexBasis: 0,
                              flexShrink: 0,
                              flexGrow: 1,
                              minWidth: emToPx(15.625),
                            }}
                          >
                            {getContentNode({ content })}
                          </ListItem>
                        );
                      })}
                    </List>
                  </ScrollView>
                )}
              </>
            )}

            {CONTENT_SECTION_TYPE_VALUE_PAGE === contentSection.type && (
              <Row
                breakpointList={{ all: Infinity }}
                col={{ all: 1 }}
                gutter={0.625}
              >
                {contentList.map((content, index) => {
                  if (index > 4) {
                    return null;
                  }
                  return (
                    <Col key={content.id}>{getContentNode({ content })}</Col>
                  );
                })}
              </Row>
            )}

            {CONTENT_SECTION_TYPE_VALUE_CAMPAIGN === contentSection.type && (
              <>
                {matches ? (
                  <Row
                    breakpointList={{ all: Infinity }}
                    col={{ all: 1 }}
                    gutter={0.625}
                  >
                    {contentList.map((content, index) => {
                      if (index > 4) {
                        return null;
                      }
                      return (
                        <Col key={content.id}>
                          {getContentNode({ content })}
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <ScrollView scrollEnabled={true} horizontal={true}>
                    <List inline={true} gutter={0.625} noWrap={true}>
                      {contentList.map((content, index) => {
                        if (index > 4) {
                          return null;
                        }
                        return (
                          <ListItem
                            key={content.id}
                            style={{
                              minWidth: emToPx(15.625),
                              flexBasis: 0,
                              flexShrink: 0,
                              flexGrow: 1,
                            }}
                          >
                            {getContentNode({ content })}
                          </ListItem>
                        );
                      })}
                    </List>
                  </ScrollView>
                )}
              </>
            )}
          </ContentSection>
        );
      })}

      <HasSeenRoom>
        <View
          style={{
            backgroundColor: colorIndex++ % 2 === 0 ? "#f7f7f7" : "#ffffff",
          }}
        >
          <LastSeenRoomContainer />
        </View>
      </HasSeenRoom>
    </>
  );
}

export default IndexContainer;
