// dependencies
import React, { useState, useCallback, useMemo } from "react";
import { StyleSheet } from "react-native";
import { NextPage } from "next";
import { useRouter } from "next/router";
import { useIntl } from "react-intl";

// components
import Button from "@gdf/resources/src/components/Button2";
import Heading from "@gdf/resources/src/components/Heading";
import { Row, Col } from "@gdf/resources/src/components/Grid";
import Form from "@gdf/resources/src/components/Form";
import SelectCapacity from "@gdf/resources/src/components/SelectCapacity";
import SearchHomeBox from "@gdf/resources/src/components/SearchHomeBox";
import CheckBoxForWork from "@gdf/resources/src/components/CheckBoxForWork";

// containers
import AutoCompleteCity from "@gdf/resources/src/containers/AutoCompleteCity";
import HeadlessDatePickerWithTextInput from "@gdf/resources/src/containers/HeadlessDatePickerWithTextInput";

// constants
import { METHOD_VALUE_GET } from "@gdf/shared/src/constants/methods";
import { SEO_PAGE_VALUE_HOME } from "@gdf/resources/src/constants/seo";

// libraries
import {
  fromDate,
  fromString,
} from "@gdf/resources/src/libraries/EnhancedDate";
import { get, generateUri } from "@gdf/shared/src/libraries";
import { emToPx } from "@gdf/resources/src/libraries";

// contexts
import { useRouters } from "@gdf/resources/src/contexts/routers";
import { useConfiguration } from "@gdf/resources/src/contexts/configuration";

function normalizeParameterList({ event, parameterList }) {
  const newParameterList = {
    ...parameterList,
  };

  if ("checkinCheckout" === event.target.name) {
    const start = event.target.value.start;
    const end = event.target.value.end;
    if (start) {
      newParameterList.checkin = start.format("Y-m-d");
    } else {
      delete newParameterList.checkin;
    }

    if (end) {
      newParameterList.checkout = end.format("Y-m-d");
    } else {
      delete newParameterList.checkout;
    }

    if (start && start.isGreaterThan(end)) {
      newParameterList.checkin = end.format("Y-m-d");
      delete newParameterList.checkout;
    }
  } else if ("city" === event.target.name) {
    newParameterList.city = get(event.target.value, "city");
    newParameterList.lat = get(event.target.value, "lat");
    newParameterList.lng = get(event.target.value, "lng");
  } else {
    if ("checkbox" === event.target.type) {
      newParameterList[event.target.name] = event.target.checked
        ? event.target.value
        : undefined;
    } else {
      newParameterList[event.target.name] = event.target.value;
    }
  }

  return newParameterList;
}

function serializeSearchQuery({ parameterList }) {
  return {
    checkin: get(parameterList, "checkin"),
    checkout: get(parameterList, "checkout"),
    city: get(parameterList, "city"),
    lat: get(parameterList, "lat"),
    lng: get(parameterList, "lng"),
    capacity: get(parameterList, "capacity"),
    pros: get(parameterList, "pros"),
  };
}

const styles = StyleSheet.create({
  title: {
    textAlign: "center",
    fontSize: emToPx(1.625),
  },
});

type PropsType = {};

const SearchContainer: NextPage<PropsType> = () => {
  const intl = useIntl();

  const router = useRouter();

  const { frontRouter } = useRouters();

  const today = useMemo(() => fromDate().startOfDays(), []);

  /* gestion du formulaire */
  const [parameterList, setParameterList] = useState({
    capacity: "1",
  });

  const configuration = useConfiguration();

  const handleChange = useCallback(
    (event) => {
      setParameterList(normalizeParameterList({ parameterList, event }));
    },
    [parameterList]
  );

  const actionUrl = generateUri({
    router: frontRouter,
    name: "App.Action.RoomList",
    query: serializeSearchQuery({ parameterList }),
    parameters: {
      lang: intl.locale,
    },
    host: null,
    scheme: null,
  });

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      router.push(actionUrl);
    },
    [actionUrl, router]
  );

  /**
   * Gère la modification du champ de recherche par localisation.
   */
  function handleChangeCity(event) {
    const { value } = event.target;

    if ("hotel" === value?.type) {
      router.push(
        generateUri({
          router: frontRouter,
          name: "App.Action.RoomWithSlug",
          parameters: {
            lang: intl.locale,
            slug: value?.place_id,
          },
        })
      );
    } else {
      handleChange(event);
    }
  }

  return (
    <SearchHomeBox>
      <Form
        action={actionUrl}
        method={METHOD_VALUE_GET}
        onSubmit={handleSubmit}
      >
        <Row breakpointList={{ all: Infinity }} col={{ all: 1 }} gutter={0.5}>
          {configuration?.channel?.website?.seo?.[SEO_PAGE_VALUE_HOME]
            ?.title && (
            <Col>
              <Heading level={1} textStyle={[styles.title]}>
                {configuration.channel.website.seo[SEO_PAGE_VALUE_HOME].title}
              </Heading>
            </Col>
          )}

          <Col>
            <AutoCompleteCity
              inputProps={{ "data-gtm": "home-search-place" }}
              name="city"
              onSelectResult={handleChangeCity}
            />
          </Col>

          <Col>
            <HeadlessDatePickerWithTextInput
              startInputProps={{
                "data-gtm": "home-search-checkin",
              }}
              endInputProps={{
                "data-gtm": "home-search-checkout",
              }}
              initialDate={fromDate()}
              name="checkinCheckout"
              start={
                fromString(parameterList?.checkin ?? null, "Y-m-d") || null
              }
              end={fromString(parameterList?.checkout ?? null, "Y-m-d") || null}
              min={today}
              onSelect={handleChange}
            />
          </Col>

          <Col>
            <SelectCapacity
              selectProps={{
                "data-gtm": "home-search-capacity",
              }}
              value={get(parameterList, "capacity", "")}
              onChange={handleChange}
            />
          </Col>

          <Col>
            <CheckBoxForWork
              checkboxProps={{
                "data-gtm": "home-search-nature",
              }}
              name="pros"
              mode="room"
              checked={get(parameterList, "pros", false)}
              value="1"
              onChange={handleChange}
            />
          </Col>

          <Col>
            <Button
              data-gtm="home-search-submit"
              type="submit"
              label={intl.formatMessage({
                id: "front.containers.index.form.field.search",
              })}
              appearance="secondary"
            />
          </Col>
        </Row>
      </Form>
    </SearchHomeBox>
  );
};

export default SearchContainer;
