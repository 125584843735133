// dependencies
import React from "react";
import { useIntl } from "react-intl";
import {
  View,
  StyleSheet,
  ImageSourcePropType,
  AccessibilityRole,
} from "react-native";

// components
import Text from "../Text";
import Heading from "../Heading";
import Stack from "../Stack";
import Button from "../Button";
import Image from "../Image";
import { Row, Col } from "../Grid";
import BaseComponent from "../BaseComponent";

// hocs
import useViewportMedia from "../../hocs/useViewportMedia";

// libraries
import { emToPx } from "../../libraries";

type PropsType = {
  title: string;
  subtitle: string;
  about: string;
  imageSource: ImageSourcePropType;
  to: string;
  fadeColor?: string;
};

const styles = StyleSheet.create({
  contentWrapper: {
    width: "100%",
    height: "100%",
  },
  content: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflow: "hidden",
    flexDirection: "column",
  },
  contentItem: {
    overflow: "hidden",
  },
  contentDescription: {
    flex: 1,
  },
  fadeBackgroundColor: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "1em",
  },
});

const ContentPage: React.FunctionComponent<PropsType> = (props) => {
  const { title, subtitle, about, to, imageSource } = props;

  const intl = useIntl();

  const { matches } = useViewportMedia({ queries: [{ minWidth: 1100 }] });

  return (
    <BaseComponent>
      <View accessibilityRole={"article" as AccessibilityRole}>
        <Row
          breakpointList={{ sm: 1100, md: Infinity }}
          col={{ sm: 1, md: 2 }}
          gutter={0.5}
        >
          {!matches && (
            <Col>
              <View>
                <Image source={imageSource} resizeMode="contain" alt={title} />
              </View>
            </Col>
          )}

          <Col>
            <View
              style={[
                styles.contentWrapper,
                { minHeight: !matches ? emToPx(18.75) : null },
              ]}
            >
              <View style={styles.content}>
                <Stack marginTop={2} />

                <View style={styles.contentItem}>
                  <Heading
                    level={2}
                    size={"lg"}
                    textStyle={{ fontWeight: "500" }}
                  >
                    {title}
                  </Heading>

                  {subtitle && (
                    <>
                      <Heading level={3} size={"sm"}>
                        {subtitle}
                      </Heading>
                    </>
                  )}
                </View>

                <Stack marginTop={1} />

                <View style={[styles.contentItem, styles.contentDescription]}>
                  <Text>{about}</Text>
                </View>

                <View style={styles.contentItem}>
                  <Text>
                    <Button
                      accessibilityLabel={intl.formatMessage({
                        id: "app.component.homepage.about",
                      })}
                      to={to}
                    >
                      {intl.formatMessage({
                        id: "app.component.homepage.about",
                      })}
                    </Button>
                  </Text>
                </View>

                <Stack marginTop={1} />
              </View>
            </View>
          </Col>

          {matches && (
            <Col>
              <View>
                <Image
                  ratio={6 / 16}
                  source={imageSource}
                  resizeMode="contain"
                  alt={title}
                />
              </View>
            </Col>
          )}
        </Row>
      </View>
    </BaseComponent>
  );
};

ContentPage.defaultProps = {
  fadeColor: "#ffffff",
};

export default ContentPage;
