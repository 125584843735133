// dependencies
import React, { useMemo } from "react";
import { ScrollView } from "react-native";
import PropTypes from "prop-types";

// components
import RoomCard from "@gdf/resources/src/components/RoomCard";
import { List, ListItem } from "@gdf/resources/src/components/List";
import { Row, Col } from "@gdf/resources/src/components/Grid";

// hocs
import useViewportMedia from "@gdf/resources/src/hocs/useViewportMedia";

// contexts
import { useConfiguration } from "@gdf/resources/src/contexts/configuration";
import { useRoute } from "@gdf/resources/src/contexts/route";
import { useRouters } from "@gdf/resources/src/contexts/routers";

// api
import { roomContentCampaignContainer } from "@gdf/resources/src/api/room";
import { pricingEstimateContentCampaignContainer } from "@gdf/resources/src/api/pricing";

// libraries
import { generateUri } from "@gdf/shared/src/libraries";

// hooks
import { useResponse } from "../../hooks/fetcher";

function ContentCampaign(props) {
  const { contentId, context, perPage } = props;

  const { frontRouter } = useRouters();

  const configuration = useConfiguration();

  const route = useRoute();

  const { matches } = useViewportMedia({ queries: [{ minWidth: 800 }] });

  const roomsResponse = useResponse(
    roomContentCampaignContainer({
      configuration,
      contentId,
      query: {
        per_page: perPage,
      },
    })
  );

  const roomList = useMemo(
    () => roomsResponse.data?.data ?? [],
    [roomsResponse.data?.data]
  );

  const pricingsResponse = useResponse(
    pricingEstimateContentCampaignContainer({
      configuration,
      roomReferenceList:
        roomList.length > 0 ? roomList.map((room) => room.reference) : null,
    })
  );

  const pricings = pricingsResponse.data?.data;

  function handlePress({ roomReference, context }) {
    // TODO: GTM : ACTION
    // @magsout Tu peux récupérer l'id de la campaign (content) avec la varaible `contentId`.
  }

  return (
    <>
      {matches ? (
        <Row
          breakpointList={{
            xs: 500,
            sm: 800,
            md: 1000,
            lg: Infinity,
          }}
          col={{ xs: 1, sm: 2, md: 3, lg: 5 }}
          gutter={0.625}
        >
          {roomList.map((room) => {
            return (
              <Col key={room.reference}>
                <RoomCard
                  context={context}
                  pricing={pricings?.[room.reference]}
                  flux="block"
                  room={room}
                  to={generateUri({
                    router: frontRouter,
                    name: "App.Action.RoomWithSlug",
                    parameters: {
                      ...route.getParameters(),
                      slug: room.slug,
                    },
                  })}
                  onPress={handlePress}
                />
              </Col>
            );
          })}
        </Row>
      ) : (
        <ScrollView scrollEnabled={true} horizontal={true}>
          <List gutter={1} inline={true}>
            {roomList.map((room) => {
              return (
                <ListItem key={room.reference}>
                  <RoomCard
                    context={context}
                    pricing={pricings?.[room.reference]}
                    flux="block"
                    room={room}
                    to={generateUri({
                      router: frontRouter,
                      name: "App.Action.RoomWithSlug",
                      parameters: {
                        ...route.getParameters(),
                        slug: room.slug,
                      },
                    })}
                    onPress={handlePress}
                  />
                </ListItem>
              );
            })}
          </List>
        </ScrollView>
      )}
    </>
  );
}

ContentCampaign.propTypes = {
  contentId: PropTypes.string,
  context: PropTypes.oneOf(["home", "campaign", "search"]),
  perPage: PropTypes.string,
};

ContentCampaign.defaultProps = {
  perPage: "10",
};

export default ContentCampaign;
